import React from "react"
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"
import IhkC from "../images/Footer/IHK-C.inline.svg"
import IhkP from "../images/Footer/IHK-P.inline.svg"
//import bmwk from "../images/Footer/Logo_BMWK.svg"

const Footer = () => {
  const data = useStaticQuery(graphql`
      query {
          logoWildau: file(relativePath: { eq: "Footer/th-wildau.png" }) {
              childImageSharp {
                  fluid(maxWidth: 303, quality: 50) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          }
          ITsicherheit: file(relativePath: { eq: "Footer/IT-Sicherheit.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 303, quality: 50) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          }
          mittelstand: file(relativePath: { eq: "Footer/Mittelstand-Digital.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 303, quality: 50) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          }
          IHKOB: file(relativePath: { eq: "Footer/IHK-OB.png" }) {
              childImageSharp {
                  fluid(maxWidth: 246, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          }
          DIZ: file(relativePath: { eq: "Footer/DIZ.png" }) {
              childImageSharp {
                  fluid(maxWidth: 246, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          }
          bmwk: file(relativePath: { eq: "Footer/bmwk.png" }) {
            childImageSharp {
                fluid(maxWidth: 303, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
      }
  `)

  return (
    <footer>
      <div className="featured-companies">
        <div className="container">
                    <h3>Assoziierte Partner:</h3>
                    <ul>
                        <li>
                            <a href="https://www.cottbus.ihk.de/" target="_blank" rel="noreferrer" aria-label="Link IHK Cottus">
                                <IhkC  className="image svg"/>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.ihk-ostbrandenburg.de/" target="_blank" rel="noreferrer" aria-label="Link IHK Cottus">
                                <Img className="image" fluid={data.IHKOB.childImageSharp.fluid} />
                            </a>
                        </li>
                        <li>
                            <a href="https://wis.ihk.de/ihk/potsdam.html" target="_blank" rel="noreferrer" aria-label="Link IHK Cottus">
                                <IhkP className="image svg" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.diz-bw.de/" target="_blank" rel="noreferrer" aria-label="Link IHK Cottus">
                                <Img className="image" fluid={data.DIZ.childImageSharp.fluid} />
                            </a>
                        </li>
                    </ul>
        </div>
      </div>
      <div className="footer">
        <div className="inner">
          <div className="sponsors">
            <div className="image wildau">
           
            </div>
            <div className="image bmwi">
                {/* <Img className="image" fluid={data.bmwk.childImageSharp.fluid} /> */}
               
            </div>
            <div className="image it">
               {/* <Img fluid={data.ITsicherheit.childImageSharp.fluid} />  */}
               <br/>
               <Img fluid={data.logoWildau.childImageSharp.fluid} />
            </div>
            <div className="image mittelstand">
                {/* <Img fluid={data.mittelstand.childImageSharp.fluid} /> */}
               {/*  <span>© Copyright <b>ALARM 2020</b></span> <br />
            <span>Alle Rechte vorbehalten.</span> */}
            <br/>
            <Link to="/impressum#impressum">Impressum und Datenschutzerklärung</Link>
            <br/> 
               
            </div>
          </div>
          <div className="copyright">
           
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
