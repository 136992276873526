import React, {useContext} from 'react'
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import {GlobalStateContext} from "../../../context/GlobalContextProvider"

const ReginaInterview2023 = (state) => {
    state = useContext(GlobalStateContext);
    const data = useStaticQuery(graphql`
        query {
            pressReginaInterview202306: file(relativePath: { eq: "Publications/Press/202306interview.png" }) {
                childImageSharp {
                    fluid(maxHeight: 500, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <div className={state.pressId}>
            <div className="box">
                <div className="image-container">
                    <a  href="https://bc.pressmatrix.com/de/profiles/6b0f1aad0817/editions/934e9713606b39bc12ff/pages/page/14" 
                        target="_blank" rel="noreferrer">
                    <Img fluid={data.pressReginaInterview202306.childImageSharp.fluid} className="image"/> 
                    </a>
                </div>
                <p>
                IT-Schulung so wichtig <br />wie Brandschutz
                </p>
                <p className="subtitle" >
                    <a href="https://bc.pressmatrix.com/de/profiles/6b0f1aad0817/editions/934e9713606b39bc12ff/pages/page/14"
                        target="_blank" rel="noreferrer" 
                        aria-label="Blogartikel">
                        Link zu Website
                    </a>
                </p>
            </div>
        </div>
    )
};

export default ReginaInterview2023;