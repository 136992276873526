import React, {useContext} from 'react'
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import {GlobalStateContext} from "../../../context/GlobalContextProvider"

const THNews202306 = (state) => {
    state = useContext(GlobalStateContext);

    const data = useStaticQuery(graphql`
        query {
            press202306THNews: file(relativePath: { eq: "Publications/Press/th-news-202306.pdf" }) {
                name
                publicURL
            }
            press202306THNewsImage: file(relativePath: { eq: "Publications/Press/th-news-202306.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            } 
        }
    `)

    return (
        <div className={state.pressId}>
            <div className="box">
                <div className="image-container">
                    <p className="subtitle" >
                    <a href={data.press202306THNews.publicURL} target="_blank" rel="noreferrer">
                        <Img fluid={data.press202306THNewsImage.childImageSharp.fluid} 
                        className="image"/> 
                    </a>
                    </p>
                </div>
                <p>Abschlußveranstaltung</p>
               
                <p className="subtitle">
                    Pressemitteilung <br/>
                    der TH-Wildau
                    </p>
            </div>
        </div>
    )
};

export default THNews202306;