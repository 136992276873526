import React from 'react'
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"

const ProjectBlurb = () => {
    const data = useStaticQuery(graphql`
        query {
            thWildauLogo: file(relativePath: { eq: "Footer/th-wildau.png" }) {
                childImageSharp {
                    fluid(maxWidth: 200, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            methods: file(relativePath: { eq: "Banner/videobanner/video3.png" }) {
                childImageSharp{
                    fluid(maxWidth: 375, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <div className="project-blurb">
            <span className="anchor" id="project">anchor</span>
            <div className="container">
                <div className="inner-blurb">
                    <div className="content">
                        <h3>ALARM</h3>
                        <h4>Awareness Labor KMU Informationssicherheit</h4>
                        <p>Kleinst- bis mittlere Unternehmen erheben, verarbeiten und nutzen viele sensible Daten mit Hilfe von digitalen IT-Lösungen, unterschätzen jedoch häufig die Risiken und Bedrohungslage durch immer raffinierter agierende Angreifer.
                            Sorglosigkeit über Informationssicherheit sowie Unkenntnis oder Verletzung von betrieblichen Richtlinien oder nichtexistierende Informationssicherheitsrichtlinien sind Risiken für Unternehmen aller Art und Größe.
                            Die vielfältigen Schwachstellen stellen Sicherheitsmängel dar, die zukünftige verzögerte Folgen für KMU/KKU haben können. Hier setzt das multidisziplinäre Forschungsprojekt „Awareness Labor KMU Informationssicherheit“ (ALARM Informationssicherheit) an.
                        </p>

                        <h4>Die Technische Hochschule Wildau (TH Wildau)</h4>
                        <p>Die TH Wildau des Landes Brandenburg ist seit ihrer Gründung im Jahre 1991 eine forschungsstarke Fachhochschule mit positiven Einfluss auf die Lehrqualität.
                            Sie hat sich in vielen Drittmittelprojekten auch überregional als kompetenter und verlässlicher Partner erwiesen. Für die TH Wildau steht dabei immer die Anwendungsorientierung im Vordergrund,
                            Forschung und Entwicklung (F&E) sowie Wissen- und Technologietransfer gehören dabei zusammen.
                        </p>
                        <div className="th-logo">
                            <a href="https://www.th-wildau.de/" target="_blank" rel="noreferrer" aria-label="Link zur Website der TH-Wildau">
                                <Img className="image" fluid={data.thWildauLogo.childImageSharp.fluid}/>
                            </a>
                        </div>
                    </div>
                    <div className="content">
					    <h3>Projekt</h3>
                        <p>Das „Awareness Labor KMU (ALARM) Informationssicherheit“ baut innerhalb von drei Jahren ein Gesamtszenario zur Sensibilisierung und Unterstützung
                            der KKU/KMU für Informationssicherheit bis hin zu deren Selbsthilfe auf. Im Projekt werden iterativ in drei Phasen, agil und partizipatorisch,
                            ein innovatives Prozess-Szenario für Informationssicherheit mit analogen und digitalen erlebnisorientierten Szenarien sowie „Vor-Ort-Angriffen“ und weiteren Überprüfungen, wie z. B. Awareness-Messungen, Quiz und Tests  entwickelt.
                            Das Gesamtszenario soll zu der dringend notwendigen Sensibilisierung
                            von Führungskräften und Mitarbeitenden und zu einer gezielten Personalentwicklung in KMU/KKU führen, wie sie derzeit breitenwirksam noch nicht vorhanden
                            ist. Dazu wird IT-Sicherheit im Zusammenhang mit den zunehmend digitalen Arbeitsprozessen konkret (be-)greifbar gemacht,
                            gleichzeitig werden die Menschen emotional berührt und aktiv in die Entwicklung von Maßnahmen einbezogen. Eine nachhaltige und unternehmensweite
                            Informationssicherheitskultur soll damit aufgebaut werden.
                        </p>
                       
                        <div className="box">
                            <div className="image-container">
                                <a  href="https://alarm.wildau.biz/alarm_media/alarm-intro-video.mp4" 
                                    target="_blank" rel="noreferrer">
                                <Img fluid={data.methods.childImageSharp.fluid} className="image"/> 
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectBlurb
