import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import React, {useContext} from "react"
import {GlobalDispatchContext, GlobalStateContext} from "../context/GlobalContextProvider"

import menu from "../images/Header/menu-bar.png"

//import publicationsState from ".."

const Header = () => {
    const dispatch = useContext(GlobalDispatchContext);
    const state = useContext(GlobalStateContext);

    const data = useStaticQuery(graphql`
        query {
            logo: file(relativePath: { eq: "Header/alarm-logo-noSubs-trans.png" }) {
                childImageSharp {
                    fluid(maxWidth: 80, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            britishFlag: file(relativePath: { eq: "Header/british-flag.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 30, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    /* once LS and PUB content is added
        <Link to="/#learningScenarios" onClick={() => dispatch({type: "CHANGE_LS_ANALOG"})}>Analoge Serious Games</Link>
        <Link to="/#learningScenarios" onClick={() => dispatch({type: "CHANGE_LS_DIGITAL"})}>Digitale Serious Games</Link>
        <Link to="/#learningScenarios" onClick={() => dispatch({type: "CHANGE_LS_ONSITE"})}>Vor-Ort-Angriffe</Link>
        <Link className="last-child" to="/#learningScenarios" onClick={() => dispatch({type: "CHANGE_LS_SECURITY"})}>Sicherheitskonzepte</Link>

        <Link to="/#publications" onClick={() => dispatch({type: "CHANGE_PUB_PRESS"})}>Presse</Link>
        <Link to="/#publications" onClick={() => dispatch({type: "CHANGE_PUB_ACTIVITIES"})}>Aktivitäten</Link>
        <Link to="/#publications" onClick={() => dispatch({type: "CHANGE_PUB_EVENTS"})}>Veranstaltungen</Link>
        <Link to="/#publications" onClick={() => dispatch({type: "CHANGE_PUB_FLYER"})}>Flyer & Broschüren</Link>
        <Link to="/#publications" onClick={() => dispatch({type: "CHANGE_PUB_NETWORKING"})}>Vernetzung</Link>
        <Link className="last-child" to="/#publications" onClick={() => dispatch({type: "CHANGE_PUB_PUBLICATIONS"})}>Wiss. Publikationen</Link>
    */

    return(
        <header>
            <div className="container">
                <div className="inner-header">
                    <div className="logo">
                        <Link to="/"><Img fluid={data.logo.childImageSharp.fluid}/></Link>
                    </div>
                    <nav className={state.navMenuActive}>
                        <ul>
                            <li><Link to="/#banner" onClick={() => dispatch({type: "DEACTIVATE_NAVMENU"})}>Home</Link></li>
                            <li><Link to="/#project" onClick={() => dispatch({type: "DEACTIVATE_NAVMENU"})}>Projekt</Link></li>
                            <li><Link to="/#learningScenarios" onClick={() => dispatch({type: "DEACTIVATE_NAVMENU"})}>Maßnahmen</Link>
                                <div className="dropdown-content">
                                    <Link   to="/#learningScenarios" 
                                            onClick={() => dispatch({type: "CHANGE_LS_ANALOG"})}>Analoge Serious Games</Link>
                                    <Link to="/#learningScenarios" 
                                            onClick={() => dispatch({type: "CHANGE_LS_DIGITAL"})}>Digitale Serious Games</Link>
                                    {/* <Link to="/#learningScenarios">Vor-Ort-Angriffe</Link> */}
                                    <Link className="last-child" 
                                    to="/#learningScenarios"
                                    onClick={() => dispatch({type: "CHANGE_LS_SECURITY"})}>Sicherheitskonzepte</Link>
                                </div>
                            </li>
                            <li><Link to="/#publications" onClick={() => dispatch({type: "DEACTIVATE_NAVMENU"})}>Öffentlichkeitsarbeit</Link>
                                <div className="dropdown-content">
                                    <Link to="/#publications" onClick={() => dispatch({type: "CHANGE_PUB_PRESS"})}>Presse</Link>
                                    <Link to="/#publications"  onClick={() => dispatch({type: "CHANGE_PUB_EVENTS"})}>Veranstaltungen</Link>
                                    <Link to="/#publications" onClick={() => dispatch({type: "CHANGE_PUB_FLYER"})}>Flyer & Broschüren</Link>
                                    <Link to="/#publications" onClick={() => dispatch({type: "CHANGE_PUB_NETWORKING"})}>Vernetzung</Link>
                                    <Link className="last-child" to="/#publications" onClick={() => dispatch({type: "CHANGE_PUB_PUBLICATIONS"})}>Wiss. Publikationen</Link>
                                </div>
                            </li>
                            <li><Link to="/#team" onClick={() => dispatch({type: "DEACTIVATE_NAVMENU"})}>Team</Link></li>
                            <li><Link to="/#partner" onClick={() => dispatch({type: "DEACTIVATE_NAVMENU"})}>Partner</Link></li>
                            <li><Link to="/#contact" onClick={() => dispatch({type: "DEACTIVATE_NAVMENU"})}>Kontakt</Link></li>
                        </ul>
                    </nav>
                    <div className="language">
                        <Link to="/en"><Img fluid={data.britishFlag.childImageSharp.fluid} className="englisch"/></Link>
                    </div>
                    <div className="menuToggle">
                        <button onClick={() => dispatch({type: "TOGGLE_NAVMENU"})}><img src={menu} alt="Menü" id="menu"/></button>
                    </div>
                </div>
            </div>
        </header>
    )
};

export default Header
