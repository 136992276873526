import React from 'react'
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"

const TeamBlurp = () => {
    const data = useStaticQuery(graphql`
        query {
            imageScholl: file(relativePath: { eq: "Team/scholl.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            imageSchuktomow: file(relativePath: { eq: "Team/schuktomow.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            imageGube: file(relativePath: { eq: "Team/gube.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            imageKoppatz: file(relativePath: { eq: "Team/koppatz.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            imageTippelskirch: file(relativePath: { eq: "Team/tippelskirch.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            imageUnknown: file(relativePath: { eq: "Team/unkown-person-silhouette.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            imageBechthold: file(relativePath: { eq: "Team/bechthold.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            imageWalch: file(relativePath: { eq: "Team/walch.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            imageGerlach: file(relativePath: { eq: "Team/gerlach.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            imageMujkic: file(relativePath: { eq: "Team/mujkic.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            imageEhrlich: file(relativePath: { eq: "Team/ehrlich.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            imageZakrewski: file(relativePath: { eq: "Team/zakrewski.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            imageEdich: file(relativePath: { eq: "Team/edich.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            imagePrott: file(relativePath: { eq: "Team/prott.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            imageZientek: file(relativePath: { eq: "Team/zientek.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            arrow: file(relativePath: { eq: "ui-arrow.png" }) {
                childImageSharp {
                    fluid(maxWidth: 74, quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <div className="team-blurb">
            <span className="anchor" id="team">anchor</span>
            <div className="container">
                
                <div className="rows">
                    <div className="column">
                        <div className="box">
                            <div className="image-container">
                                <Img fluid={data.imageScholl.childImageSharp.fluid}/>
                            </div>
                            <p>Prof. Dr. Margit Scholl</p>
                            <p className="subtitle">Projektmanagement</p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="box">
                            <div className="image-container">
                            <Img fluid={data.imageSchuktomow.childImageSharp.fluid}/>
                            </div>
                            <p>Regina Schuktomow</p>
                            <p className="subtitle">Operative Projektleitung</p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="box">
                            <div className="image-container">
                            <Img fluid={data.imageMujkic.childImageSharp.fluid}/>
                            </div>
                            <p>Olesja Mujkic</p>
                            <p className="subtitle">Design</p>
                        </div>
                    </div>
                    <div className="content">
                        <h3>Team</h3>
                        <p>Das Team von ALARM Informationssicherheit zeichnet sich
                        durch seine Erfahrungswerte in verwandten Projekten und
                        seine Interdisziplinarität aus.</p>
                    </div>

                    
                    <div className="column">
                        <div className="box">
                            <div className="image-container">
                            <Img fluid={data.imagePrott.childImageSharp.fluid}/>
                            </div>
                            <p>Frauke Prott</p>
                            <p className="subtitle">Qualitätsmanagement & Digitale Serious Games</p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="box">
                            <div className="image-container">
                            <Img fluid={data.imageTippelskirch.childImageSharp.fluid}/>
                            </div>
                            <p>Hubertus von Tippelskirch</p>
                            <p className="subtitle">F&E - Didaktik und Evaluation</p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="box">
                            <div className="image-container">
                            <Img fluid={data.imageKoppatz.childImageSharp.fluid}/>
                            </div>
                            <p>Peter Koppatz</p>
                            <p className="subtitle">F&E - Vor-Ort-Angriffe und Programmierung</p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="box">
                            <div className="image-container">
                            <Img fluid={data.imageEhrlich.childImageSharp.fluid}/>
                            </div>
                            <p>Peter-Ernst Ehrlich</p>
                            <p className="subtitle">Labor-Ingenieur</p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="box">
                            <div className="image-container">
                            <Img fluid={data.imageZientek.childImageSharp.fluid}/>   
                            </div>
                            <p>Bernhard Zientek</p>
                            <p className="subtitle">Qualitätsmanagement</p>
                        </div>
                    </div>
                    <div className="column">   
                    </div>
                    <div className="column">
                        
                    </div>
                    <div className="column">
                        
                    </div>
                    <div className="content">
                        <h4>Ehemalige Mitarbeiterinnen und Mitarbeiter,</h4>
                        <h4> die uns zeitweise unterstützt haben.</h4>
                    </div>

                    <div className="column">
                        <div className="box">
                            <div className="image-container">
                            <Img fluid={data.imageZakrewski.childImageSharp.fluid}/>
                            </div>
                            <p>Mario Zakrewski</p>
                            <p className="subtitle">Evaluation, analoge Serious Games</p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="box">
                            <div className="image-container">
                            <Img fluid={data.imageGerlach.childImageSharp.fluid}/>
                            </div>
                            <p>Josephine Gerlach</p>
                            <p className="subtitle">F&E - Assistenz</p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="box">
                            <div className="image-container">
                            <Img fluid={data.imageBechthold.childImageSharp.fluid}/>
                            </div>
                            <p>Julian Bechthold</p>
                            <p className="subtitle">F&E - digitale Serious Games, Programmierung und Design</p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="box">
                            <div className="image-container">
                            <Img fluid={data.imageEdich.childImageSharp.fluid}/>
                            </div>
                            <p>Denis Edich</p>
                            <p className="subtitle">F&E - digitale Serious Games, Programmierung und Design</p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="box">
                            <div className="image-container">
                            <Img fluid={data.imageWalch.childImageSharp.fluid}/>
                            </div>
                            <p>Christin Walch</p>
                            <p className="subtitle">F&E - Öffentlichkeitsarbeit und Projektmanagement</p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="box">
                            <div className="image-container">
                            <Img fluid={data.imageGube.childImageSharp.fluid}/>   
                            </div>
                            <p>Stefanie Gube</p>
                            <p className="subtitle">F&E - betriebliche Awareness und KMU-Readiness</p>
                        </div>
                    </div>
                    <div className="ui-arrows">
                        <div className="image left">
                            <Img fluid={data.arrow.childImageSharp.fluid} className="left"/>
                        </div>
                        <div className="image right">
                            <Img fluid={data.arrow.childImageSharp.fluid} className="right"/>
                        </div>
                    </div>
                </div>   
            </div>
        </div>
    )
}

export default TeamBlurp
