import React from 'react'
import {StaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"

class PartnerBlurb extends React.Component {
    state = { 
        adress: "#partner"
    } 

    sendMail(mailName, domain, tld){
        this.setState({
            adress: "mailto:" + mailName + "@" + domain + "." + tld
        })
    }

    /*
        <a href="#" className="cryptedmail"
            data-name="info"
            data-domain="example"
            data-tld="org"
            onClick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;">
        </a>
    */

    render(){
        const mailAdress = this.state.adress;

        return (
            <StaticQuery
                query = {graphql`
                    query {
                        gamebook: file(relativePath: { eq: "Partner/gamebook-logo-red.png" }) {
                            childImageSharp {
                                fluid(maxWidth: 155, quality: 90) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                        knownSense: file(relativePath: { eq: "Partner/known-sense.png" }) {
                            childImageSharp {
                                fluid(maxWidth: 230, quality: 90) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                        sudile: file(relativePath: { eq: "Partner/sudile.jpg" }) {
                            childImageSharp {
                                fluid(maxWidth: 230, quality: 90) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                        thinkingObjects: file(relativePath: { eq: "Partner/thinking-objects.png" }) {
                            childImageSharp {
                                fluid(maxWidth: 230, quality: 90) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                `}
                render = {data => ( 
                    <div className="partner-blurb">
                        <span className="anchor" id="partner">anchor</span>
                        <div className="container">
                            <h3>Partner</h3>
                        </div>
                        <div className="experimental-game">
                            <div className="container">
                                <div className="inner-blurb">
                                    <div className="content info">
                                        <h4>Gamebook Studio</h4>
                                        <div className="company">
                                            <div className="logo">
                                                <Img fluid={data.gamebook.childImageSharp.fluid}/>
                                            </div>
                                            <div className="contact-person">
                                                <p><br />
                                                    <br />
                                                    Ulrike Küchler <br />
                                                    +49 30 3700 5607 <br />
                                                    <a href={mailAdress} className="cryptedmail"
                                                        data-name="info"
                                                        data-domain="gamebook"
                                                        data-tld="studio"
                                                        onClick={() => this.sendMail("admin", "gamebook", "studio")}>
                                                    </a> <br/>
                                                    <a href="http://www.gamebook.studio/" rel="noreferrer" target="_blank">www.gamebook.studio</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content description">
                                        <p>
                                            At Gamebook storytelling is our business – and our passion. Our vision is to create and deliver the best storytelling games available. 
                                            Our mission is to provide the necessary knowledge and the network along with a tool set that is easy to use and comprehensive at the same time.
                                            <br/><br/>
                                            We achieve these goals by thinking out of the box and combining our web-based software with agile networks where we bring IT developers, 
                                            producers, game designers, artists and authors together in a way nobody ever imagined – all in one place. Since our foundation in 2010, 
                                            this idea has driven us and our team has been continuously growing ever since. 
                                            <br/><br/>
                                            Today, we offer Games and Training-as-a-Service solutions for all kinds of projects: 
                                            from IP based interactive Visual Novels to custom learning experiences that facilitate the power of good stories.

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="known-sense">
                            <div className="container">
                                <div className="inner-blurb">
                                    <div className="content description">
                                        <p> known_sense - das bedeutet vor allem den Blick hinter die Kulissen mit dem notwendigen Wissen um die "Black Box Mensch" mit dem Ergebnis lebendiger Security-Kommunikation und wirksamer 
                                            Awareness. Und das seit 2002. Damit waren wir die erste reine Awareness-Agentur weltweit und sind heute noch die einzige, die die komplette Wertschöpfungskette von der Security-Wirkungsforschung über Konzeption, 
                                            Consulting, Produktentwicklung, Design, Medienproduktion, Training, Gamification, Evaluation u.v.m. bedient. In den beinahe 2 Jahrzehnten seit Gründung haben unsere Berater, Kreativen, 
                                            Psychologen und Trainer für mehr als 100 kleine und große Kunden in fast 50 Ländern und in 25 Sprachen Maßnahmen durchgeführt. Maßnahmen in dem Wissen um die Paradoxien, die die Menschen im Kontext Sicherheit antreibt und - O-Ton zahlreicher Kunden - 
                                            "komische" Dinge tun lässt. Dinge, die ein genaues Hinschauen und besondere Antworten erfordern. <br/> <br/>
                                            Bei ALARM ist known_sense zuständig für die qualitative, tiefenpsychologische Wirkungsforschung, d. h. für eine Grundlagenstudie Informationssicherheit und Security Awareness bei KMU sowie für einen Konzept- 
                                            und abschließend einen Produkttest, außerdem für die Entwicklung, Visualisierung und Produktion von 7 spielbasierten, analogen Serious Games.
                                        </p>
                                    </div>
                                    <div className="content info">
                                        <h4>known_Sense</h4>
                                        <div className="company">
                                            <div className="contact-person">
                                                <p>
                                                    Dietmar Pokoyski <br />
                                                    02203 1831618 <br />
                                                    <a href={mailAdress} className="cryptedmail"
                                                        data-name="pokoyski"
                                                        data-domain="known-sense"
                                                        data-tld="de"
                                                        onClick={() => this.sendMail("pokoyski", "known-sense", "de")}>
                                                    </a> <br/>
                                                    <a href="http://www.known-sense.de/" rel="noreferrer" target="_blank">www.known-sense.de</a>
                                                </p>
                                            </div>
                                            <div className="logo">
                                                <Img fluid={data.knownSense.childImageSharp.fluid}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="thinking-objects">
                            <div className="container">
                                <div className="inner-blurb">
                                    <div className="content info">
                                        <h4>Thinking Objects</h4>
                                        <div className="company">
                                            <div className="logo">
                                                <Img fluid={data.thinkingObjects.childImageSharp.fluid}/>
                                            </div>
                                            <div className="contact-person">
                                                <p><br />
                                                    <br />
                                                    Martina Vogt <br />
                                                    +49 152- 05706824 <br />
                                                    <a href={mailAdress} className="cryptedmail"
                                                        data-name="martina.vogt"
                                                        data-domain="to"
                                                        data-tld="com"
                                                        onClick={() => this.sendMail("martina.vogt", "to", "com")}>
                                                    </a> <br/>
                                                    <a href="https://to.com/" rel="noreferrer" target="_blank">www.to.com</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content description">
                                        <p>
                                            IT. Leistungsstark. Sicher. Kundenorientiert. Die inhabergeführte Thinking Objects GmbH, kurz TO, ist ein kompetenter IT-Dienstleister mit Schwerpunkt IT-Security. 
                                            Seit mehr als 25 Jahren bietet die TO marktgerechte Lösungen zur Unterstützung, Entlastung, Optimierung und Sicherung des IT-Betriebs in großen und mittelständischen Unternehmen sowie Konzernen. <br/> <br/>

                                            Innerhalb des Projekts ALARM bringt die TO ihre Expertise insbesondere in der Konzeption und Durchführung von „Vor-Ort-Angriffen“ ein. Die Auswertung enthält Handlungsempfehlungen zu niederschwelligen Sicherheitskonzepten, 
                                            wonach die Unternehmen besser befähigt werden, selbstständig IT-sicherheitsrelevante Entscheidungen zu treffen.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sudile">
                            <div className="container">
                                <div className="inner-blurb">
                                    <div className="content description">
                                        <p>Sudile GbR ist ein seit 2004 in Potsdam ansässiges Unternehmen, das sich mit Entwicklung und Anpassung von Lernplattformen, Beratungen zur E-Learning und Blended Learning Implementation, 
                                            Trainings zu E-Learning Themen und didaktischen Konzeptionen zu Lernangeboten beschäftigt. <br/><br/>
                                            
                                            Herr Dr. Rainer Brüggemann deckt als mathematischer Chemiker im Rahmen von ALARM Beziehungen (Matching) 
                                            zwischen und innerhalb der folgenden zwei Gruppen auf: a) anonymisierten Probanden und b) Cyber-Attacken. Der sogenannte Matching-Forschungsansatz trägt zu Awareness-Messungen bei, 
                                            ermöglicht ein besseres Verständnis der Beziehungen zwischen Cyber-Attacken und betroffenen Unternehmen sowie eine Charakterisierung des Lernerfolgs.
                                        </p>
                                    </div>
                                    <div className="content info">
                                        <h4>Sudile</h4>
                                        <div className="company">
                                            <div className="contact-person">
                                                <p>
                                                    Dr. Rainer Brüggemann <br />
                                                    <a href={mailAdress} className="cryptedmail"
                                                        data-name="brg_home"
                                                        data-domain="web"
                                                        data-tld="de"
                                                        onClick={() => this.sendMail("brg_home", "web", "de")}>
                                                    </a> <br/>
                                                    <a href="https://www.sudile.com/" rel="noreferrer" target="_blank">www.sudile.com</a>
                                                </p>
                                            </div>
                                            <div className="logo">
                                                <Img fluid={data.sudile.childImageSharp.fluid}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            />
        )
    }
}

export default PartnerBlurb