import React from "react"
import "../styles/styles.scss"
import Layout from "../components/layout"
import Header from "../components/header"
import Banner from "../components/banner"
import ProjectBlurb from "../components/projectBlurb"
import GoalsAndMethodsBlurbBlurb from "../components/goalsAndMethodsBlurb"
//import LearningScenariosBlurb from "../components/learningScenariosBlurb"
import PublicationsBlurb from "../components/publicationsBlurb"
import TeamBlurb from "../components/teamBlurb"
import PartnerBlurb from "../components/partnerBlurb"
import ContactBlurb from "../components/contactBlurb"
import DigitalLearningScenarios from "../components/LearnSzenarios/dLS"
//import AnalogeScenarios from "../components/LearnSzenarios/aLS"
// <LearningScenariosBlurb />
const IndexPage = () => (
  <Layout>
  <div>
  <Header />
  <Banner />
  <ProjectBlurb />
  <GoalsAndMethodsBlurbBlurb />
  <DigitalLearningScenarios />
  <PublicationsBlurb />
  <PartnerBlurb />
  <TeamBlurb />
  <ContactBlurb />
</div>
  </Layout>
)

export default IndexPage
