/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

//import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Header from "./header"
import Footer from "./footer"
import { Helmet } from "react-helmet"

const Layout = ({ children }) => {

  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <>
    <Helmet
    title="Security -- Alarm Projektwebsite" defer={false}>
      <html lang="de" />
      <meta name="description" content="Security -- ALARM -- Neue Wege für mehr Informationssicherheit durch Awarenesstraining" />
      <meta name="keywords" content="Security, ALARM, Awareness, KMU, Training, Sicherheitsmängel, Sicherheitsschulungen, Awareness Labor KMU Informationssicherheit, Schwachstellen " />
      <meta property="og:url" content="https://alarm.wildau.biz"/>
      <meta property="og:type" content="website"/>
      <meta property="og:title" content="ALARM - Security Awareness KMU"/>
      <meta property="og:description" content="ALARM -- Neue Wege für mehr Informationssicherheit."/>
  </Helmet>
  
    <Header />
        <main>{children}</main>
    <Footer />

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout