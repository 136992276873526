import React, {useContext} from 'react'
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import {GlobalStateContext} from "../../../context/GlobalContextProvider"

const RBBAktuell2022 = (state) => {
    state = useContext(GlobalStateContext);
    const data = useStaticQuery(graphql`
        query {
            rbb24aktuell: file(relativePath: { eq: "Publications/Press/rbb24aktuell.png" }) {
                childImageSharp {
                    fluid(maxHeight: 500, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <div className={state.pressId}>
            <div className="box">
                <div className="image-container">
                    <a  href="https://www.rbb24.de/studiofrankfurt/beitraege/2022/11/th-wildau-ostbrandenburg-cyber-angriffe.html" 
                        target="_blank" rel="noreferrer">
                    <Img fluid={data.rbb24aktuell.childImageSharp.fluid} className="image"/> 
                    </a>
                </div>
                <p>
                Zum Tag der Computersicherheit 2022
                </p>
                <p className="subtitle" >
                    <a href="https://www.rbb24.de/studiofrankfurt/beitraege/2022/11/th-wildau-ostbrandenburg-cyber-angriffe.html"
                        target="_blank" rel="noreferrer" 
                        aria-label="Blogartikel">
                        Artikel auf RBB24
                    </a>
                </p>
            </div>
        </div>
    )
};

export default RBBAktuell2022;