import React from 'react'
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"

const GoalsAndMethodsBlurb = () => {
    const data = useStaticQuery(graphql`
        query {
            roadmap: file(relativePath: { eq: "MethodsAndGoals/Roadmap.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 615, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            methods: file(relativePath: { eq: "MethodsAndGoals/Methods.png" }) {
                childImageSharp {
                    fluid(maxWidth: 375, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <div className="goals-and-methods-blurb">
            <span className="anchor" id="g-and-m">anchor</span>
            <div className="goals">
                <div className="container">
                    <div className="inner-blurb">
                        <div className="content info">
                            <div className="image">
                                <Img fluid={data.roadmap.childImageSharp.fluid}/>
                            </div>
                        </div>
                        <div className="content description">
                            <h3>Ziele und Inhalte</h3>
                            <p>
                                Es werden Defizitbereiche wichtiger Geschäftsprozesse systematisch und gemeinsam mit Pilot-KMU und -Handwerksbetrieben anhand konkreter Tätigkeiten erschlossen und Sicherheits- sowie Kompetenzprofile abgeleitet. 
                                Um Nachhaltigkeit auch breitenwirksam zu erreichen, werden aktivierende Sensibilisierungsmaßnahmen analog und digital entwickelt, praktisch vielfältig erprobt und evaluiert. 
                                Best-Practice-Anleitungen mit Erfolgsgeschichten teilnehmender Unternehmen werden bundesweit über assoziierte Transferpartner veröffentlicht, um weitere Unternehmen anzusprechen. 
                                Neuartige betriebliche Awareness-Messungen führen zu Reifegradaussagen für KMU/KKU. Qualitäts- und Ergebnissicherung kombiniert mit Risikomanagement und einer begleitenden Evaluation komplementieren die Wirkungsanalysen. 
                                Die Vernetzung aller Beteiligten wird bundesweit verstärkt.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="methods">
                <div className="container">
                    <div className="inner-blurb">
                        <div className="content description">
                            <h3>Methoden</h3>
                            <p> 
                                Zur Erreichung der Projektziele werden spezifische und auf die jeweiligen Bedürfnisse abgestimmte Schulungs- und Sensibilisierungskonzepte sowie Lernmaterialien entwickelt, getestet und evaluiert. 
                                Als Lernansätze werden <i>Game-based und Accelerated Learning</i> hierfür der Übertragung auf erlebnisorientierte Serious Games im Bereich Informationssicherheit zugrunde gelegt. 
                                Nach bisherigen Studien und Forschungsarbeiten von Prof. Dr. Scholl und des Forschungspartners known_sense erwiesen sich sowohl Emotionalisierung wie auch Motivation als essentiell für Lernprozesse in der Informationssicherheit. 
                                Alle entwickelten Materialien werden am Projektende kostenlos allen Unternehmen per Download oder Online-Zugang zur Verfügung gestellt, 
                                so dass bundesweit eine Verbesserung der Awareness und die Erhöhung des IT-Sicherheitsniveaus in Deutschland erreicht werden.
                            </p>
                        </div>
                        <div className="content info">
                            <div className="image methods">
                                <Img fluid={data.methods.childImageSharp.fluid}/>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GoalsAndMethodsBlurb